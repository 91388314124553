.block__accordion {
  border-radius: 15px;
  border: 1px solid #E2E2E2;
  overflow: hidden;
  .block__title {
    text-align: left;
    padding: 27px 30px;
    h2 {
      color: $dark-gray;
      font-size: 20px;
    }
  }
  .cell {
    padding: 0 !important;
  }
  .accordion {
    margin-bottom: 0;
    border-top: 1px solid #E2E2E2;
    padding: 20px 0 14px;
    .accordion-title {
      font-size: 18px;
      font-weight: 500;
      color: #696969;
      padding: 12px 37px 12px 30px;
      position: relative;
      @include transition(all);
      &:after {
        @include transition(all);
        content: '\e909';
        font-family: herxen, sans-serif;
        position: absolute;
        right: 37px;
        top: 10px;
        color: #C1C1C1;
        font-size: 11px;
        opacity: 0;
      }
      &:hover,
      &:focus {
        color: $primary-color;
        background: $springwood;
      }
    }
    .accordion-content {
      padding: 10px 30px;
      background: transparent;
      color: #A8A8A8;
      :last-child {
        margin-bottom: 0;
      }

    }
    .accordion-item {
      @include transition(all);
      &.is-active {
        padding: 20px 0;
        background: $springwood;
        .accordion-title {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}