.block__counter {
  .h2 {
    font-size: rem-calc(18);
    @include breakpoint(medium) {

      font-size: rem-calc(30);
    }
  }
  .counter {
    position: relative;
    @include breakpoint(large) {
      padding: 0 10px;
    }
    .numbers {
      display: block;
      position: relative;
    }
    span.number {
      float: left;
      background-image: url(../images/svg/elements/counterbg.svg);
      text-align: center;
      font-weight: 900;
      color: #FFF3E3;
      width: 28px;
      height: 42px;
      margin: 0;
      font-size: 24px;
      line-height: 42px;
      background-size: 28px 42px;
      background-position: center center;
      @include breakpoint(medium) {
        width: 41px;
        height: 73px;
        margin: 0 2px;
        font-size: 50px;
        line-height: 73px;
        background-size: 41px 73px;
      }

    }
  }
}