.block-3-column-option-block {
  a {
    font-size: 14px;
    font-weight: 500;
  }
  @include breakpoint(medium down) {
    h2 {
      font-size: 1.5rem;
    }
  }
  .card-1 {
    .card-section {
      background: $tertiary-color;
      * {
        color: $white;
      }
    }
  }
}