.social-share{
	&__title{
		font-size: rem-calc(20);
	}
	.box-share{
		width:rem-calc(34);
		display: inline-block;
		height:rem-calc(34);
		margin-right:rem-calc(5px);
		cursor: pointer;
		line-height: 34px;
		border-radius: 5px;
		a{
			width: 100%;
			height: 100%;
			display: block;
			color: $white;
			text-align: center;
			.icon {
				display: inline-block;
			}
		}
		&.facebook {
			background: #547BBC;
			&:hover {
				background: darken(#547BBC, 15%);
			}
		}
		&.twitter {
			background: #78CBEF;
			&:hover {
				background: darken(#78CBEF, 15%);
			}
		}
		&.linkedin {
			background: #1384C7;
			&:hover {
				background: darken(#1384C7, 15%);
			}
		}
		&.whatsapp {
			background: #3AAA35;
			&:hover {
				background: darken(#3AAA35, 15%);
			}
		}
		&.mail {
			background: #AFAFAF;
			&:hover {
				background: darken(#AFAFAF, 15%);
			}
		}
	}
}