/*
[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{
	@include border-radius(50px);
	margin-bottom: 0;
	height: rem-calc(51);
	border:#D1D1D1 2px solid;
	font-size: rem-calc(16);
	font-weight: 300;
	padding: rem-calc(12px 25px);
	color: $dark-gray;
	@include placeholder{
		color: $dark-gray;
		opacity: 1;
	}
	&:focus{
		border:#D1D1D1 2px solid;
	}
}
*/





textarea{
	//@include border-radius(25px);
	height: rem-calc(218);
	resize: none;
}

.form-group{
	margin-bottom: rem-calc(20px);
}
.is-invalid-input:not(:focus) {
	border-color: $secondary-color;
	background-color: $white;
	color: $secondary-color;


	&::placeholder{
		color: $secondary-color;
	}

}
.valid:not(:focus) {
	border-color: rgba(151, 221, 126, 0.4);
	color: $tertiary-color;
}

$background: $white;
$select-color: #ddd;
$select-background: $white;
$select-width: 100%;
$select-height: rem-calc(47);


[type='radio'],
[type='checkbox']{
	opacity: 0;
	margin:0;
	width: 0;
	height: 0;
	+ label{
		font-size: $input-font-size;
		color: $input-color;
		position: relative;
		margin:rem-calc(0 15 0 0);
		padding-left: rem-calc(40);
		&::before{
			position: absolute;
			width:  31px;
			height: 31px;
			border: 2px solid $select-color;
			content: '';
			display: inline-block;
			top: rem-calc(-1);
			left: 0;
		}
		&::after{
			@include transition(opacity);
			position: absolute;
			content: '';
			display: inline-block;
			opacity: 0;
		}
	}
	&:checked{
		+ label{
			&::after{
				opacity: 1;
			}
		}
	}
	&:focus {
		+ label {
			&:before {
				border-color: $tertiary-color;
			}
		}
	}
	&.is-invalid-input {
		+ label {
			&::before {
				border-color: $secondary-color;
			}
		}
	}
}

[type='radio']{
	+ label{
		&::before{
			content: '';
			border-radius: 100%;
		}
		&::after{
			width:  21px;
			height: 21px;
			border-radius: 100%;
			top: rem-calc(4);
			left: rem-calc(5);
			background: $primary-color;
		}
	}
}

[type='checkbox']{
	+ label{
		&::before{
			border-radius: 10px;
		}
		&::after{
			content: $icon-checkmark;
			color: $primary-color;
			font-family: herxen;
			font-size: rem-calc(18);
			top: rem-calc(-1);
			left: rem-calc(7);
		}
	}
	&.is-invalid{
		+ label{
			color:red;
		}
	}
	&:checked{
		&.is-invalid {
			+ label {
				color: $input-color;
			}
		}
	}
}

select{
	border-radius:$input-radius;
	padding-top:0;
	padding-bottom:0;
	margin:0;
	&:valid {
		color: $input-color;
	}
}

.select-hidden {
	display: none;
	visibility: hidden;
	padding-right: 10px;
}
.select {
	//border-radius: ;
	cursor: pointer;
	display: block;
	position: relative;
	color: $select-color;
	width: $select-width;
	height: $select-height;
	select{
		margin:0;
	}
}
.select-styled {
	@include transition(all);
	border-radius:$input-radius;
	border:$input-border;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $select-background;
	padding: 10px 1.5625rem;
	font-size: $input-font-size;

	&:after {
		@include transition(all);
		color: #B2B2B2;
		font-family: herxen;
		content:"\e911";
		position: absolute;
		top: rem-calc(13);
		font-size: rem-calc(14);
		right: rem-calc(16);
	}
	&:active, &.active {
		border-radius:$input-radius $input-radius 0 0;

		&:after {
			transform: rotate(180deg);
		}
	}
}

.select-options {
	display: none;
	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	z-index: 999;
	margin: 0;
	padding: 0;
	list-style: none;
	background-color: $white;
	border:$input-border;
	border-top:0;
	max-height: 300px;
	overflow: hidden;
	overflow-y: auto;;
	li {
		@include transition(all 0.15s ease-in);
		margin: 0;
		padding: rem-calc(10px 25px);
		font-size: $input-font-size;
		color: #B2B2B2;
		&:hover {
			background: $springwood;
		}
		&[rel="hide"] {
			display: none;
		}
	}
}

input.is-invalid, .select.is-invalid {
	border-color: red;
}
.basket-content-wrapper {
	label {
		&.h6 {
			font-weight: 700;
		}
	}
}