.payment{
	&__header{
		display: block;
		width: 100%;
		ol {
			line-height: 44px;
			font-size: rem-calc(12);
			margin: 0;
			list-style: none;
			list-style-position: inside;
			@include breakpoint(medium) {
				font-size: rem-calc(16);
				list-style-type: decimal;
			}
			li {
				float: left;
				background: $springwood;
				width: auto;

				text-align: left;
				white-space: nowrap;
				font-weight: 500;
				font-size: inherit;
				padding: 0 10px;
				&:first-child {
					border-radius: 5px 0 0 5px;
					@include breakpoint(small only) {
						width: 27%;
					}
				}
				&:nth-child(2) {
					@include breakpoint(small only) {
						width: 47%;
						padding-left: 18px;
					}
				}
				&:last-child {
					border-radius: 0 5px 5px 0;
					@include breakpoint(small only) {
						width: 26%;
						padding-left: 18px;
					}
				}
				@include breakpoint(medium) {
					width: 33.3333%;
					text-align: center;
				}
				a {
					font-size: inherit;
					line-height: initial;
				}
				&.is-active {
					color: white;
					background: $primary-color;
					position: relative;
					z-index: 0;
					&:after {
						content: '';
						width: 20px;
						height: 100%;
						position: absolute;
						right: -20px;
						border-style: solid;
						border-width: 22px 0 22px 10px;
						@include breakpoint(medium) {
							border-width: 22px 0 22px 15px;
						}
						border-color: transparent transparent transparent $primary-color;
					}
				}
				&.is-correct {
					color: #76B959;
					background: darken($springwood, 5%);
					position: relative;
					//z-index: 1;
					span {
						&:after {
							content: '\e908';
							font-family: herxen, sans-serif;
							font-size: 13px;
							margin-left: 5px;
							position: relative;
							z-index: 2;
						}
					}
					a {
						color: inherit;
					}
					&:after {
						content: '';
						width: 20px;
						height: 100%;
						position: absolute;
						right: -20px;
						border-style: solid;
						border-width: 22px 0 22px 10px;
						@include breakpoint(medium) {
							border-width: 22px 0 22px 15px;
						}
						border-color: transparent transparent transparent darken($springwood, 5%);
						z-index: 1;
					}
					&:before {
						content: '';
						width: 20px;
						height: 100%;
						position: absolute;
						right: -36px;
						border-style: solid;
						border-width: 22px 0 22px 10px;
						@include breakpoint(medium) {
							border-width: 22px 0 22px 15px;
						}
						border-color: transparent transparent transparent $primary-color;
						z-index: 2;
					}
					&.hide-arrow {
						&:before {
							display: none;
						}
					}
				}
				& + .is-correct {
					&:before {
						right: auto;
						left: 0;
						display: none;
					}
				}
				&.white-arrow {
					&:before {
						border-color: transparent transparent transparent white;
						z-index: 1;
						right: -22px;
						transform: scale(1.2);
					}
				}
				&:last-child {
					&.is-active {
						&:after {
							display: none;
						}
					}
				}
			}
		}

		span{

			&.is-active {

				strong {
					background: $primary-color;
				}
			}
			&.is-correct{
				color: #76B959;
				strong {
					background: #76B959;
				}
				i{
					font-size: rem-calc(13);
					margin-left: rem-calc(2);
					@include breakpoint(medium) {
						margin-left: rem-calc(7);
					}
					position: relative;
					top: rem-calc(1);
				}
			}
		}
	}
	&-list{
		margin:rem-calc(0 0 20);
		list-style-type: none;
		li{
			background-color: $tertiary-color;
			color: $white;
			padding: rem-calc(10 15);
			@include breakpoint(medium) {
				padding: rem-calc(20 35);
			}
			margin-bottom: 10px;
			border-radius: 3px;
			//border-top: 1px solid #E2E2E2;
			&:last-child {
				//border-bottom: 1px solid #E2E2E2;
			}
			&.transaction {
				background-color: $gray;
			}
			&.total {
				background: $white;
				color: $tertiary-color;
				border: 1px solid rgba($gray,0.5);
			}
		}
		.logo-icon {
			display: inline-block;
			margin-right: 10px;
		}
		&__number{
			display: inline-block;
			font-size: rem-calc(32);
			font-weight: 300;
			line-height: 1.8rem;
			span {
				font-size: rem-calc(16);
				display: block;
				font-weight: 500;
				opacity: 0.6;
			}

		}
		&__price{
			font-size: rem-calc(24);
			font-weight: 900;

		}
		.deletebutton {
			padding: 8px;

			border-radius: 0;
			font-size: rem-calc(18);
			@include transition(all);
			&:hover {
				transform: scale(1.1);
			}
		}
		button{
			cursor: pointer;
		}
		i{
			color: $white;
		}
	}
	h6{
		font-weight: 700;
	}
}

.step-indicator {
	overflow: hidden;
	padding: 0;
	list-style: none;
	list-style-position: inside;
	font-size: 12px;
	color: #62615f;
	border-radius: .5em;
	background-color: #f6f3ed;
	margin: 40px 0;
	.is-previous {
		color: #36c;
	}
	@include breakpoint(medium) {
			list-style-type: decimal;
			font-size: 14px;
	}
	li {
		float: left;
		width: 33.333%;

		text-align: center;
		padding: .7em 0;
		@include breakpoint(small only) {
			width: auto;
			padding: 10px 10px;
		}
		&.second, &.last {
			padding-left: 15px;
		}
		position: relative;
		font-size: 12px;
		@include breakpoint(medium) {
			font-size: 16px;
		}
		&.is-active, &.is-processed {
			color: #fff;
			background-color: #36c;
		}
		&.is-active {
			&:after, &:before {
				position: absolute;
				top: 0;
				width: 0;
				height: 0;
				content: '';
				border-width: 1.55em 0 1.55em 1.55em;
				border-style: solid;
				transform: rotate(360deg);
			}
		}
		&.is-processed {
			&:after, &:before {
				position: absolute;
				top: 0;
				width: 0;
				height: 0;
				content: '';
				border-width: 1.55em 0 1.55em 1.55em;
				border-style: solid;
				transform: rotate(360deg);
			}
		}
		&.is-active:before, &.is-processed:before {
			right: 90%;
			border-color: #36c #36c #36c #f6f3ed;
		}
		&.is-active:after, &.is-processed:after {
			left: 90%;
			border-color: #f6f3ed #f6f3ed #f6f3ed #36c;
		}
		&.is-active:first-child:before, &.is-processed:first-child:before, &.is-active:last-child:after, &.is-processed:last-child:after {
			border-color: #36c;
		}
		&.is-active--success:last-child, &.is-processed--success:last-child {
			background-color: #090;
		}
		&.is-active--success:last-child:before, &.is-processed--success:last-child:before {
			border-color: #090 #090 #090 #f6f3ed;
		}
		&.is-active--success:last-child:after, &.is-processed--success:last-child:after {
			border-color: #090;
		}
	}
}



@media screen and (min-width: 30em) {
	.step-indicator__steps {
		&.is-active:before, &.is-processed:before {
			right: 95%;
		}
	}
}

@media screen and (min-width: 30em) {
	.step-indicator__steps {
		&.is-active:after, &.is-processed:after {
			left: 95%;
		}
	}
}

@media screen and (min-width: 48em) {
	.step-indicator__steps {
		padding: 0.75em;
	}
}