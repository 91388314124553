.content-carousel{
	.slick-list{
		overflow: visible;
	}
	.slick-dots {
		padding: 0 10px;
		bottom:rem-calc(9);
		li {
			width: 18px;
			margin: 0;

			button {
				&:before {
					content: '';
					border-radius: 100%;
					width: 9px;
					height: 9px;
					background: $primary-color;
					opacity: 0.25;
					color: green;
				}

			}

			&.slick-active {
				button {
					&:before {
						opacity: 1;
					}
				}
			}
		}
	}
	&.slick-slider {
		margin-bottom: 0;
	}
}