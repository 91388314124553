.off-canvas.position-right {
  transform: none !important;
}

.off-canvas-content.is-open-right.has-transition-push {
  transform: none !important;
}

.off-canvas {
  opacity: 0;
  overflow: visible;
  &-wrapper {
    -webkit-overflow-scrolling: touch;
  }
  @include transition(opacity);
  &:after {
    content: '';
    position: fixed;
    margin-left: -80px;
    z-index: -1;
    top: 0;
    bottom: 0;
    width: 80px;
    @include linear-gradient--horizontal(rgba(0,0,0,0), rgba(0,0,0,0.19));
    pointer-events: none;
  }
  .menushape {
    position: fixed;
    margin-left: -45px;
    top: 0;
    width: 49px;
    height: 90px;
    background-image: url(../images/svg/elements/menushape.svg);
    .menu-button {
      background: transparent !important;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 0 !important;
      right: auto;
    }
  }
  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    //overflow: hidden;
    //overflow-y: auto;
    touch-action: pan-y;
    //-webkit-overflow-scrolling: touch;
    padding: 28px 0 30px;
    @include breakpoint(medium) {
      padding: 48px 0 50px;
    }
    .button {
      margin-left: 34px;
      @include breakpoint(medium) {
        margin-left: 64px;
      }
    }
  }
  .bottom {
    border-top: 1px solid #E2E2E2;
    margin-top: 30px;

    padding: 30px 10px 30px 34px;
    @include breakpoint(medium) {
      margin-top: 50px;

      padding: 50px 50px 50px 64px
    }
  }
  .menu {
    margin-left: 34px;
    @include breakpoint(medium) {
      margin-left: 64px;
    }
    margin-right: 10px;
    a {
      padding: 12px 12px 12px 0;
      @include transition(all);
      &:hover {
        color: $primary-color;
      }
    }
  }
  .menu.first {
    margin-bottom: 15px;
    a {
      font-size: 20px;
      font-weight: 500;
      color: #5C5C5C;
      &:hover {
        color: $primary-color;
      }
    }
    .is-active {
      a {
        color: $primary-color;
      }
    }
  }
  .menu.second {
    margin-top: 37px;
    a {
      padding: 11px 11px 11px 0;
    }
    .is-active {
      a {
        color: $primary-color;
      }
    }
  }
}

.off-canvas.is-closed {
  transform: none;
  z-index: -1;
  //opacity: 1;
  //visibility: visible;
  //background: red;
}

.off-canvas.is-open {
  opacity: 1;
}
.off-canvas.position-right.is-open {
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.js-off-canvas-overlay.is-overlay-fixed {
  -webkit-overflow-scrolling: touch;
  touch-action: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0.0);
}