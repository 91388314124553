@each $spacerSize, $spacerValue in $spacers {
	.spacer--#{$spacerSize}-px{
		height: #{$spacerValue}px;
	}
	.spacer--#{$spacerSize}-vh{
		height: #{$spacerValue}vh;
	}
}

.container-spacer{
	height: rem-calc(200);
}