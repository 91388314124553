.checkmark-list {

  li {
    font-size: rem-calc(16);
    line-height: rem-calc(25);
    font-weight: 400;
    @include breakpoint(small only) {
      display: block !important;
      text-align: center;
      width: 100%;
      //padding-left: 20px;
      line-height: 2.2rem;
    }
    @include breakpoint(medium) {
      line-height: 2.2rem;
      display: block;
      width: 50%;
    }
    @include breakpoint(large) {
      line-height: rem-calc(20);
      text-align: left;
      width: auto;

    }
    &:before {
      font-family: herxen;
      content: '\e908';
      color: $primary-color;
      font-weight: 700;
      font-size: rem-calc(20);
      display: inline-block;
      vertical-align: middle;
      margin-right: rem-calc(10);
      @include breakpoint(medium) {
        margin-right: rem-calc(10);
      }
    }
    @include breakpoint(large) {
      & + li {
        margin-left: rem-calc(60) !important;
      }
    }
    @include breakpoint(medium down) {
      & + li {
        margin-left: 0 !important;
      }
    }
  }
  @include breakpoint(medium down) {
    width: 80%;
    margin: 0 auto;
  }
}