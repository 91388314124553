.block__prizes {
  position: relative;
  //padding-bottom: 95px;
  &-wrapper {
    .block__prizes {
      position: relative;
      margin-top: -140px;
      //transform: translateY(-50%);
    }
  }
}

.prize,
.sidebar_block {
  &.card {
    @include transition(all);
    box-shadow: 0 7px 16px -7px rgba(0,0,0,0.01);
    &:hover {
      transform: scale(1.02);
      box-shadow: 0 7px 16px rgba(0, 0, 0, 0.25);
    }
    @include breakpoint(large) {
      margin-bottom: 0;
    }
  }
  h2 {
    font-size: 20px;
  }

  .card-section {
    @include transition(all);
    padding: 20px 30px;
    .h3 {
      margin-bottom: 0;
    }
    .icon {
      font-size: 15px;
      position: relative;
      top: 5px;
    }
  }

  &:hover {
    .card-section {
      background: $primary-color;
    }
  }
}