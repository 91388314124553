.page-banner {
  $p: &;
  //background: $black;
  position: relative;
  z-index: 0;

  &--large {
    height: 90vh;

    @include breakpoint(small only) {
      @include breakpoint(landscape) {
        height: 400px;
      }
    }
    @include breakpoint(medium only) {
      @include breakpoint(landscape) {
        //@media handheld {

          height: 450px;
        //}
      }
      @include breakpoint(portrait) {
        height: 600px;
      }
    }
    @include breakpoint(1025px) {
      height: 800px;
      @include breakpoint(landscape) {
        height: 800px;
      }
    }
    @include breakpoint(medium down) {
      max-height: 600px;
    }
  }

  &-carousel {
    @include breakpoint(small only) {
      height: 300px;
      overflow: hidden;
    }
  }

  .wave {
    width: rem-calc(200);
    height: rem-calc(66);
    @include breakpoint(large) {
      width: rem-calc(372.36px);
      height: rem-calc(123.37px);
    }

    background-image: url('../images/svg/elements/wave.svg');
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    .icon {
      color: #D3D3D3;
      font-size: rem-calc(16);
      margin-top: rem-calc(30);
      @include breakpoint(large) {
        font-size: rem-calc(25);
        margin-top: rem-calc(60);
      }
      display: inline-block;

      &:hover {
        color: $dark-gray;
      }
    }
  }

  .content-big {
    h1 {
      font-size: rem-calc(30);
      vertical-align: middle;
      line-height: rem-calc(34);
      @include breakpoint(medium) {

      }
      @include breakpoint(large) {
        padding-right: 30px;
        font-size: rem-calc(40);
        line-height: rem-calc(44);
      }
      @include breakpoint(xlarge) {
        padding-right: 0;
        font-size: rem-calc(60);
        line-height: rem-calc(64);
      }


    }

    p {
      font-weight: 500;
      @include breakpoint(1025px) {
        font-size: rem-calc(18);
        line-height: rem-calc(32);
      }
    }
  }

  .arrowbg {
    position: relative;
    z-index: 0;
    opacity: 1;
    @include breakpoint(medium) {
      @include breakpoint(landscape) {
        @media only screen and (min-device-height: 670px) and (max-device-height: 1024px) {
          /* Styles here */
          //color: red;
          //min-height: 170px;
        }
      }
    }
    @include breakpoint(medium) {

      //min-height: 270px;
      @include breakpoint(landscape) {
        //min-height: 170px;
      }

      @include breakpoint(portrait) {
        //min-height: 70px;
      }
    }

    img {
      @include breakpoint(medium down) {
        display: none;
      }
      pointer-events: none;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -15%);
      z-index: -1;
      width: 150%;
      @include breakpoint(medium only) {
        @include breakpoint(landscape) {
          width: 140%;
        }
      }
      @include breakpoint(large) {
        left: 50%;
        width: 148%;
        @include breakpoint(landscape) {
          @media only screen and (min-device-height: 670px) and (max-device-height: 1024px) {
            width: 140% !important;
          }
        }
      }
      max-width: none;
    }

    /*&:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: rem-calc(681.21px);
        height: rem-calc(486.43px);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url('../images/svg/arrows/big-arrow.svg');
        background-size: cover;
    }*/
    @include breakpoint(large) {
      opacity: 1;
    }
  }

  .gradientbg {
    position: relative;
    z-index: 0;

    &:before {
      content: '';
      pointer-events: none;
      position: absolute;
      z-index: -1;
      width: rem-calc(700);
      height: rem-calc(700);
      border-radius: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.51) 0%, rgba(0, 0, 0, 0) 70%);
    }
  }

  &__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 90px;
    @include breakpoint(small only) {
      @include breakpoint(landscape) {
        top: 80px;
      }
    }
    @include breakpoint(medium) {
      top: 200px;
      @include breakpoint(landscape) {
        top: 80px;
      }
    }
    @include breakpoint(large) {
      top: 200px;
      //bottom: 0;
      @include breakpoint(landscape) {
        @media only screen and (min-device-height: 670px) and (max-device-height: 1024px) {
          //top: 180px !important;
        }
      }
    }
    //transform: translateY(-50%);

    .cell {
      //height: 0;
    }

    .slick-list {
      overflow: visible;
    }
  }
  @include breakpoint(small only) {
    &.page-banner-home {
      .page-banner__content {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .carousel {
    height: 100%;


    .slider {
      height: 100%;

    }

    .slides {
      height: 100%;
      margin-bottom: 0;

      .slick-slide {
        height: 100%;
        div {
          @include breakpoint(medium down) {
            opacity: 0.86;
            background: linear-gradient(rgba(237, 28, 92, 0), rgba(255, 147, 0, 1));
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .slick-list {
      height: 100%;
      //opacity: 0.8;
    }

    .slick-track {
      height: 100%;

    }
  }

  .control {
    z-index: 1;
    color: $white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: rem-calc(30);
    cursor: pointer;
    display: none !important;
    //@include breakpoint(small only) {
    @include breakpoint(1250px) {
      display: block !important;
    }

    &.button-next {
      right: 30px;
    }

    &.button-prev {
      left: 30px;
    }

    @include breakpoint(1350) {
      font-size: rem-calc(40);
      &.button-next {
        right: 50px;
      }

      &.button-prev {
        left: 50px;
      }
    }
  }

  .slide {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .slick-dots {
    position: absolute;
    //left: 50px;

    bottom: auto;

    max-width: $global-width;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 10px;
    top: 60px;
    @include breakpoint(large) {
      top: 99px;
      padding: 0 15px;
    }
    z-index: 1000;
    text-align: left;

    li {
      width: 18px;
      margin: 0;

      button {
        &:before {
          content: '';
          border-radius: 100%;
          width: 9px;
          height: 9px;
          background: $white;
          opacity: 0.25;
          color: green;
        }

      }

      &.slick-active {
        button {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }

  &--small {
    //background: red;
  }

  &--small {
    position: relative;
    height: 200px;
    @include breakpoint(medium) {
      height: 295px;
    }

    #{$p}__content {

      @include breakpoint(small only) {
        top: calc(50%);
        margin-top: 15px;
        transform: translateY(-50%);
      }

      h1 {

        line-height: 24px;
      }

      h2 {
        font-size: 20px;
        line-height: 20px;

      }

      @include breakpoint(medium) {
        top: 154px;
        h1 {
          line-height: 41px;
        }
        h2 {

          font-size: 40px;
          line-height: 41px;
        }
      }
    }
  }

  &--medium {
    height: rem-calc(250);
    @include breakpoint(medium) {
      height: rem-calc(400);
    }
    position: relative;

    #{$p}__content {
      h1 {
        //line-height: 41px;
      }

      h2 {
        @include breakpoint(medium) {
          font-size: 40px;
          line-height: 41px;
        }
      }
      top: 70px;
      @include breakpoint(medium) {
        top: 50%;
        transform: translateY(-50%);

      }
    }
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    //opacity: 0.8;
    div {
      background:linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) );
      width: 100%;
      height: 100%;
    }
  }
  .slide--alt {
    @include breakpoint(large) {
      //#{$p}__content {
      //  top: 50%;
        //transform: translateY(-50%);
      //}
      .grid-container {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
      .arrowbg {
        padding: 0 30px 0 30px;
        * {
          color: $white;
        }
        h1 {
          font-size: 3.5rem;
          line-height: 3.7rem;
        }
        img {
          width: 100%;
          left: 0;
          top: 50%;
          transform: translate(0,-50%);
          opacity: 0.9;
        }
      }
      .button--white {
        color: $tertiary-color;
        &.hollow {
          color: $white;
        }
      }
    }
    @include breakpoint(1400px) {
      .arrowbg {
        transform: translateX(-20%);
      }
    }
  }
    @include breakpoint(large) {
      .slide {
        .grid-container {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
    }
  }
  &--alt {
    .content-slides, .slick-list, .slick-track, .slide {
      height: 100%;
    }
    @include breakpoint(large) {
      .page-banner__content {
        top: 0;
        bottom: 0;
      }
    }
  }
}

/*@media screen and (min-width: 40em) and (max-width: 45.9375em) {
  //@include breakpoint()
  @include breakpoint(landscape) {
    .grid-margin-x > .medium-landscape-4 {
      width: calc(33.33333% - 1.875rem);
      margin-left: calc(58.33333% + 0.9375rem)
    }
  }
}*/
#scrollto {
  position: relative;

  top: -37px;
}