.page-footer {
  position: relative;
  z-index: 1;
  padding: rem-calc(20 0 50);

  width: 100%;
  border-top: 1px solid $light-gray;
  color: #C3C3C3;


  h4 {
    text-transform: uppercase;
    margin-bottom: rem-calc(37px);
  }

  p {
    line-height: rem-calc(18);
    margin-bottom: rem-calc(18);
    a {
      color: $medium-gray;
      &:hover {
        color: $dark-gray;
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    //color: $white;
  }

  @include breakpoint(large only) {
    .xlarge-order-4 {
      padding-bottom: rem-calc(50px);
    }
  }

  .menu {

    &:last-of-type {
      margin-bottom: 20px;
    }
    &.lighter {
      a {
        color: #C3C3C3;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .footer {
    &__logo {
      max-width: rem-calc(100px);
    }
  }

  @include breakpoint(large down) {
    .grid-x > .cell {
      padding-bottom: rem-calc(50px);

      &:last-child {
        padding-bottom: 0;
      }

      &.small-order-2.large-order-1 {
        padding-bottom: rem-calc(0);
      }

      &.small-order-1.large-order-2 {
        padding-bottom: rem-calc(50px);
      }
    }
  }
}