.actionpage {
	@include linear-gradient--horizontal($primary-color, $secondary-color);
	padding-top: rem-calc(90);
	@include breakpoint(medium) {
		padding-top: rem-calc(120);
	}
	@include breakpoint(large) {
		padding-top: rem-calc(220);
	}
	.header-large {
		font-size: rem-calc(32);
		line-height: 1.2;
		@include breakpoint(medium) {
			font-size: rem-calc(40);
			line-height: 1.07;
		}
		@include breakpoint(large) {
			font-size: rem-calc(60);
			line-height: 1.07;
		}
	}
	&__intro {
		padding-bottom: rem-calc(30);
		@include breakpoint(large) {
			padding-bottom: rem-calc(100);
		}
	}
	&__below {
		padding-top: rem-calc(30);
		padding-bottom: rem-calc(30);
		@include breakpoint(medium) {
			padding-top: rem-calc(70);
			padding-bottom: rem-calc(70);
		}
		p {
			margin-bottom: 0.5rem;
		}
	}
	&__formContainer {
		position: relative;
		background: $white;
		border-radius: 5px;
		border: 3px solid $primary-color;
		box-shadow: 0 13px 26px rgba(0, 0, 0, 0.16);

		.inner {
			padding: rem-calc(30 20);
			@include breakpoint(medium) {
				padding: rem-calc(50 65);
			}
		}
		.actionbuttons {
			[class*=button] {

				min-width: 170px;
				margin: 0 0 15px;

				&:last-child {
					margin-bottom: 0;
				}

				@include breakpoint(medium) {
					margin: 0 10px;
				}
			}
		}
	}
	.button--gray {
		&.wiggle {
			animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
			transform: translate3d(0, 0, 0);
			backface-visibility: hidden;
			perspective: 1000px;
		}
	}
	.formContent {
		h3 {
			font-weight: 300;
		}
	}
	.footer_logos {
		padding: 40px 0;
		background: $white;
	}
}
@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%, 80% {
		transform: translate3d(2px, 0, 0);
	}

	30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%, 60% {
		transform: translate3d(4px, 0, 0);
	}
}
//.pika-lendar {
//	background: $white;
//	border: 2px solid $tertiary-color;
//	border-radius: 5px;
//	padding: 10px;
//	width: calc(100vw - 80px);
//	@include breakpoint(medium) {
//		width: 300px;
//	}
//	table {
//		width: 100%;
//	}
//}

.activecampaign-form {
	form {
		font-family: museo-sans-rounded, sans-serif !important;
	}
	label {
		font-weight: 700;
		font-size: 1rem;
		color: $tertiary-color;
		line-height: 31px;
	}
	.button {
		margin-top: 20px !important;
	}
	input {
		&._has_error {
			border-color: $secondary-color;
		}
	}
	._checkbox-radio {
		margin-bottom: 30px;
		label {
			cursor: pointer;
			padding-left: rem-calc(40);
			z-index: 2;
			position: relative;
		}
		span {
			position: relative;
			z-index: 0;
			margin:rem-calc(0 15 0 0);
						display: block;
			&::before{
				z-index: 1;
				position: absolute;
				width:  31px;
				height: 31px;
				border: 2px solid $select-color;
				content: '';
				display: inline-block;
				top: rem-calc(-1);
				left: 0;
				border-radius: 10px;
			}
			&::after{
				z-index: 1;
				@include transition(opacity);
				position: absolute;
				content: '\e908';
				color: $primary-color;
				font-family: herxen;
				font-size: rem-calc(18);
				top: rem-calc(2);
				left: rem-calc(7);
				display: inline-block;
				opacity: 0;
			}
		}
		input[type=checkbox] {
			&:checked{
				 + span {
					&:after {
						opacity: 1;
					}
				}
			}
			&:focus {
				+ span {
					&:before {
						border-color: $tertiary-color;
					}
				}
			}
			&._has_error {
				+ span {
					&:before {
						border-color: $secondary-color;
					}
				}
			}
		}
	}
	._error._above,
	._error._below {
		display: none;
	}
}