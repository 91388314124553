.button {
	@include transition(all);
	font-weight: 500;
	border: none;
	@include transition(all);
	@include breakpoint(medium) {
		position: relative;
		overflow: hidden;
		&:before {
			position: absolute;
			right: 6px;
			top: 50%;
			transform: translateY(-50%);
			content: '\e902';
			font-family: herxen, sans-serif;
			//float: right;
			//margin-right: -8px;
			//margin-left: 10px;
			width: 0;
			opacity: 0;
			text-indent: 0;
			@include transition(all 150ms);
			//overflow: hidden;
			//text-indent: -20px;
			//transform: scale(0) translateX(-10px);
			//transform-origin: right center;

		}
		&:hover, &:focus {
			padding-left: 12px;
			padding-right: 32px;
			//padding-left: 3px;
			//padding-right: 25px;
			
			&:before {
				opacity: 1;
				right: 6px;
				width: 20px;
				//width: 20px;
				//margin-right: -10px;
				//margin-left: 5px;
				//text-indent: 0;
				//transform: scale(1) translateY(0);
			}
		}
		&.no-arrow {
			&:hover, &:focus {
				padding-left: 22px;
				padding-right: 22px;
			}
			&:before {
				display: none;
			}
		}
		span {
			display: inline-block;
		}
	}
}
@each $colorName, $colorValue in $colors{
	.button--#{$colorName}{
		@include transition(all);
		@if $colorName == 'white' {
			color: $primary-color;
			background:$white;
		}@else {
			background:$colorValue;
			color:$white;
		}

		&:hover,&:focus{
			background:#{$colorValue};
			@if $colorName == 'white' {
				color: $primary-color;
				background-color: darken($white,5%);
			}@else {
				color: $white;
				background-color: darken($colorValue,3%)
			}
			&:before {
				opacity: 1;
				width: 20px;
				text-indent: 0;

			}
		}
	}
}

.button--gradient {
	@include transition(all);
	background-image: linear-gradient(140deg, $primary-color 0%, $secondary-color 50%, $primary-color 100% );
	background-size: 200% auto;
	background-position: right center;

	&:hover {
		background-position: left center;
	}
	&.button--disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}
.button .icon {
	margin-left: 10px;
	display: inline-block;
	vertical-align: middle;
}
.button--white {
	&.hollow {
		border: 2px solid $white;
		color: $white;
		&:hover,&:focus{
			border: 2px solid $white;
			color: $white;
			background-color: rgba(255,255,255,0.1);
			//border-color: darken($colorValue, 15%);
			//color: darken($colorValue,15%);
		}
	}
}
.button--grey {
	&.hollow {
		border: 2px solid $medium-gray;
		color: $dark-gray;
		&:hover,&:focus{
			border: 2px solid $dark-gray;
			color: $dark-gray;
			background-color: rgba(255,255,255,0.1);
			//border-color: darken($colorValue, 15%);
			//color: darken($colorValue,15%);
		}
	}
}