.maintenance{
  @include linear-gradient--vertical($primary-color, $secondary-color);
  height: 100vh;
  width:100%;
  display: flex;
  text-align: center;
  align-items: center;
  .logo {
    img {
      width: 30px;
      @include breakpoint(medium) {
        width: 50px;
      }
    }
    .text {
      font-size: 32px;
      padding-left: 0.5rem;
      @include breakpoint(medium) {

        font-size: 52px;
        padding-left: 1rem;
      }
    }
    margin: 0 0 50px;

  }
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.2rem;
    font-weight: 300;
  }
  @include breakpoint(medium) {
    h1 {
      font-size: 2.5rem;
    }
    h2 {
      font-size: 2rem;
    }
  }
  div{
    width: 100%;
  }
  img{
    width:rem-calc(285);
  }
  p{
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 2px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
