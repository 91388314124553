.content-card{
	@include border-radius(15px);
	border: 1px solid $light-gray;
	&__title{
		padding:rem-calc(22px 29px);
		border-bottom: 1px solid $light-gray;
		a{
			font-size: rem-calc(20);
		}
	}
	&__content{
		padding:rem-calc(25px 29px);
		h3{
			color:$tertiary-color;
		}
		p{
			margin-bottom: 10px;
		}
		.grid-x{
			&:last-of-type{
				p{
					margin-bottom: 0;
				}
			}
		}
	}
}