.post-item{
	$p: &;
	@include border-radius(15px);
	overflow: hidden;
	display: block;
	margin-bottom: 1.875rem;
	@include transition(all);
	box-shadow: 0 7px 16px -7px rgba(0,0,0,0.01);
	&:hover {
		transform: scale(1.02);
		box-shadow: 0 7px 16px rgba(0, 0, 0, 0.25);
	}
	&__content{
		padding:rem-calc(20px 27px);
		&.background--blue{
			#{$p}__subtitle,
			#{$p}__title{
				color:$white;
			}
		}
		&.background--springwood{
			#{$p}__subtitle,
			#{$p}__title{
				color:$dark-gray;
			}
		}
	}
	&__subtitle{
		font-weight: 300;
		margin-bottom: rem-calc(3px);
	}
	&__title{
		font-weight: 900;
		margin:0;
		line-height: 1.2;
	}
}