.page__container {
  position: relative;
  z-index: 0;
  background: $white;
}
.content-repeater {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
  @include breakpoint(medium) {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.fancybox-active .page__container {
  -webkit-filter: blur(25px);
  filter: blur(25px);
}