#anchor-trigger {
  position: relative;
  top: 10px;
}
.magellan-target {
  position: relative;
  top: -100px;
  height: 1px;
}
.sticky-nav {
  padding: 22px 0 0;

  a {
    color: $tertiary-color;
    font-size: 16px;
    font-weight: 500;
    //background-color: $light-gray;
    &.is-active {
      color: $tertiary-color;
    }
  }
  &.is-stuck {
    padding: 0;
    height: 51px;
    border-bottom: 1px solid $light-gray;
    margin-top: 54px !important;
    @include breakpoint(medium) {
      margin-top: 67px !important;
    }
    a {
      color: rgba($tertiary-color, 0.6);
      padding-top: 18px;
      padding-bottom: 15px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 16px;
        border-bottom: 2px solid transparent;
        @include transition(all);
        //width: 0;

      }
      &:hover {
        color: $tertiary-color;
      }
      &.is-active {
        color: $tertiary-color;
        &:before {
          border-color: $primary-color;
          right: 16px;

        }
      }
    }
  }
  @include breakpoint(medium down) {
    .menu {
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow: hidden;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      &-wrap {
        position: relative;
        width: 100%;
        &:after {

        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 30px;
        pointer-events: none;
        background: red;
          @include linear-gradient--horizontal(rgba(255,255,255,0), rgba(255,255,255,1));
        }
      }
    }
  }
  @include breakpoint(small only) {
    .menu {
      a {
        font-size: 15px;
        padding-left: 9px;
        padding-right: 9px;
      }
    }
  }
}