@each $colorName, $colorValue in $colors {
	.textcolor-#{$colorName} {
		color: $colorValue;
		* {
			color: inherit;
		}
	}
	:root .textcolor-#{$colorName} {
		color: $colorValue;
	}
}