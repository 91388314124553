$white: #FFFFFF;
$black: #000000;
$primary-color: #FF9300;
$secondary-color: #ed1c5c;
$tertiary-color: #263660;
$gray: #B2B2B2;
$springwood: #FAF7F4;
$succes: #97DD7E;
$header-color: $tertiary-color;
$colors: (
  'white' : $white,
  'black' : $black,
  'orange' : $primary-color,
  'gray' : $gray,
  'pink' : $secondary-color,
  'blue' : $tertiary-color,
  'springwood': $springwood,
);



