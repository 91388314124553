.prizes__list {
  &-item {
    border-top: 1px solid $light-gray;

    padding: rem-calc(35 0 40);

    @include breakpoint(1025px) {
      padding: rem-calc(76 0 80);
    }
    //background: red;
    .prize-number {
      font-size: rem-calc(32);
      font-weight: 100;
    }
  }
}