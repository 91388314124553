
*{
	outline: none;
}

a {
	@include transition(color);

}
a.has-arrow {
	&:after {
		content: '\e902';
		display: inline-block;
		font-family: herxen, sans-serif;
		vertical-align: middle;
		margin-left: 10px;
	}
}