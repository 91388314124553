.has-radius {
  border-radius: rem-calc(15px);
}
.has-shadow {
  box-shadow: 0 16px 64px rgba(0,0,0,0.4);
}
.has-shadow-medium {
  box-shadow: 0 13px 26px rgba(0,0,0,0.16);
}
$radius-sizes: (
        'small' : 5,
        'medium' : 10,
        'large' : 15,
);
@each $name, $value in $radius-sizes {
  .radius-#{$name} {
    border-radius: #{$value}px;
  }
}