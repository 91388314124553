/*.main-menu{
	z-index: 50;
	//position: fixed;
	//top: 0;
	//left: 0;
	background:$white;
	&__items {
		@include menu-base;
	}
}*/

.topbar {
  &__container {
    z-index: 10;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
//background: blue;
    .menu {

      a {
        font-size: rem-calc(14);
        font-weight: 500;
        color: $white;
        padding: 35px 16px;
        @include transition(all);
        position: relative;
        z-index: 0;
        border-bottom: 3px solid transparent;
        margin-bottom: -1px;
        &:before {
          content: '';
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 100%;
          opacity: 0;
          @include transition(all);
          @include linear-gradient--vertical(rgba(38,54,96,0.55), rgba(38,54,96,0));

       }
        &:hover {
          //border-bottom: 3px solid transparent;
          border-color: $white;
          &:before {
            opacity: 1;
            bottom: -1px;

            //border-width: 3px;
          }
        }
      }
      .is-active {
        a {
          //color: $primary-color;
          border-color: $white;
          &:before {
            opacity: 1;
            bottom: -1px;
          }
        }
      }
    }

  }

  &__wrapper {
    @include transition(all);
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    //padding: rem-calc(0px 23px);
    padding: 4px 0;
    width: 100%;

    @include breakpoint(large) {
      padding: 0;
    }

    &.is-stuck {
      background: $white;
      border-color: #eaeaea;
      //padding: rem-calc(0px 13px);

      .logo {
        .circle {
          fill: $primary-color;
        }

        .house {
          fill: url(#radial-gradient);
        }

        .text {
          color: #B4B4B4;
        }
      }

      .menu {
        a {
          color: $tertiary-color;
          padding: 25px 16px;
          &:hover {
            border-color: $tertiary-color;
          }
          &:before {
            @include linear-gradient--vertical(rgba(38,54,96,0.2), rgba(38,54,96,0));

          }
        }
        .is-active {
          a {
            border-color: $tertiary-color;
          }
        }
      }
    }
  }
}

$menu-icon-width: 21px;
$menu-icon-height: 21px;
$menu-icon-color: $dark-gray;
$menu-icon-color--active: $dark-gray;
.menu-button {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  background: transparent;
  right: -10px;
  @include breakpoint(medium) {
    background: $white;
    right: 0;
    margin-left: 6px;
  }
  cursor: pointer;
  width: rem-calc(45);
  height: rem-calc(45);
  padding: 0;
  position: relative;
  z-index: 1;
  &:hover,&:focus {
    padding: 0 !important;
  }

  .menu-icon {
    position: relative;
    width: $menu-icon-width;
    height: $menu-icon-height;
    display: inline-block;
    top: 2px;

    span {
      background: white;
      @include breakpoint(medium) {
        background: $menu-icon-color;
      }
      height: 2px;
      border-radius: 4px;
      width: 21px;
      display: block;
      transition: all 250ms ease-in-out;
      transform: rotate(0deg);
      transform-origin: 50% 50%;
      will-change: transform, opacity;
      position: absolute;

      &:nth-child(1) {
        top: 3px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 9px;
      }

      &:nth-child(4) {
        top: 15px;
      }
    }
  }

  &.is-active {
    .menu-icon {
      span {
        background: $menu-icon-color--active !important;

        &:nth-child(1) {
          opacity: 0;
        }

        &:nth-child(4) {
          opacity: 0;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
  }
}


.logo {
  display: inline-block;
  .white {
    fill: #fff;
  }

  .orange {
    fill: #FF9300;
  }

  .transparent {
    fill: transparent;
  }

  .gradient {
    fill: url(#radial-gradient);
  }

  svg {
    width: rem-calc(28);
    display: inline-block;
    vertical-align: middle;
  }

  .circle, .house, .text {
    @include transition(all);
  }

  .text {
    //font-family:;
    font-weight: 900;
    color: $white;
    display: inline-block;
    vertical-align: middle;
    padding-left: rem-calc(5);
    font-size: rem-calc(20);
    @include breakpoint(medium) {
      font-size: rem-calc(30);
    }
  }
}

.topbar__wrapper.is-stuck {
  .menu-button {
    .menu-icon {
      span {
        background: $menu-icon-color;
      }
    }
  }
}