h1, .h1, h2, .h2 {
  color: $header-color;
  font-weight: 900;
  font-family: $header-font-family;
  a {
    &:hover {
      color: $primary-color;
    }
  }
  &.subtitle{
    font-size: rem-calc(20) !important;
    color: $body-font-color;
    margin-bottom: 0;
  }
}

h3, .h3 {
  font-weight: 300;
  color: $primary-color;
}
h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 500;
}


.header-large {
  font-size: rem-calc(60);
  vertical-align: middle;
  line-height: rem-calc(64);
}