.buyticket {
  border: 2px solid $primary-color;
  border-radius: 3px;
  box-shadow: 0 13px 26px rgba(0,0,0,0.16);
  .card-section {
    padding: rem-calc(30 25);
  }
  h2 {
    font-size: rem-calc(30);
  }
  p {
    line-height: rem-calc(18);
  }
  .input-group {

    margin-bottom: 0;
    &-field {
      border: none;
      background: #E6DFD5;
      border-radius: 5px;
      //text-indent: 13px;
      color: $white;
      font-size: rem-calc(40);
      font-weight: 900;
      height: rem-calc(64);
      padding: 0.5rem;
      @include transition(background, 250ms);
      text-align: center;
      -moz-appearance:textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      &:focus {
        box-shadow: none;
        background: #d3ccc2;
      }
      &:valid {
        background: $primary-color;
      }
      &::placeholder {
        color: $white !important;
      }
      +.input-group-field {
        margin-left: 10px;
      }
    }
  }
  form {
    .error-message{
      display: none;
      color: $secondary-color;
      margin-top: 10px;
      text-align: right;
      margin-bottom: 0;
      i{
        font-size: rem-calc(11);
        position: relative;
        top: 0x;
      }
      //display: none;
    }
    &.is-not-valid {
      .input-group-field:valid {
        background: $secondary-color;
      }
      .error-message{
        display: block;
      }
    }
  }
}
