.sidebar_block {
  h2 {
    font-size: 20px;
  }

  &:hover {
    .card-section {
      background-color: $tertiary-color !important;
    }
    .button {
      color: $tertiary-color !important;
      &:hover {
        background: $white;
      }
    }
  }
}