body.showBlockNames [data-block]:before {
	content: attr(data-block);
	position: absolute;
	left: 0;
	background-color: green;
	color: white;
	padding: 4px;
	z-index: 99999;
}
.dp-block {
	display: block;
}
.inline-block {
	display: inline-block;
}
.uppercase {
	text-transform: uppercase;
}
.relative {
	position: relative;
}
.valign-middle {
	vertical-align: middle;
}
.fullwidth {
	width: 100%;
}