$icomoon-font-family: "herxen" !default;
$icomoon-font-path: "fonts" !default;

$icon-chevron-down: "\e911";
$icon-trash: "\e910";
$icon-checkmark: "\e908";
$icon-cross: "\e909";
$icon-minus: "\e90a";
$icon-arrow-thin-down: "\e904";
$icon-arrow-thin-up: "\e905";
$icon-arrow-thin-left: "\e906";
$icon-arrow-thin-right: "\e907";
$icon-arrow-up: "\e900";
$icon-arrow-down: "\e901";
$icon-arrow-right: "\e902";
$icon-arrow-left: "\e903";
$icon-facebook: "\e90f";
$icon-linkedin: "\e90e";
$icon-whatsapp: "\e90b";
$icon-twitter: "\e90c";
$icon-mail: "\e90d";

