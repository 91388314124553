@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?rgov00') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?rgov00') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?rgov00##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark; 
  }
}
.icon-cross {
  &:before {
    content: $icon-cross; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-arrow-thin-down {
  &:before {
    content: $icon-arrow-thin-down; 
  }
}
.icon-arrow-thin-up {
  &:before {
    content: $icon-arrow-thin-up; 
  }
}
.icon-arrow-thin-left {
  &:before {
    content: $icon-arrow-thin-left; 
  }
}
.icon-arrow-thin-right {
  &:before {
    content: $icon-arrow-thin-right; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}

